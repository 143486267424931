import * as React from "react";
import * as ReactDOM from "react-dom";
import './main.scss'



export class Hello extends React.Component {
    render() {
        return <h1>Hello world</h1>;
    }
}

ReactDOM.render(
    <Hello />,
    document.getElementById("app")
);